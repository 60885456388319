.exhibitor__container {
    width: 100vw;
    height: 100vh;
    background: linear-gradient(245.74deg, #3F70B7 -22.25%, #2A5CA5 43.34%, #1A2C4C 103.69%);
}

.exhibitor__container header {
    display: flex;
    height: 15vh;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: 0 4.75rem;
}

.exhibitor__container header img {
    height: 2rem;
}

.exhibitor__container header h1 {
    font-family: 'Montserrat';
    font-size: 2.375rem;
    font-weight: 700;
    line-height: 2.688rem;
    letter-spacing: 0;
    text-align: left;
    color: #F39315;
}

.exhibitor__board {
    height: 85vh;
    background-color: transparent;
}

.exhibitor__wrapper_row {
    height: 20%;
    width: 100%;
    display: flex;
}

.exhibitor__graphism {
    width: 15vw;
    display: flex;
}

.exhibitor__graphism .graphism {
    width: 30%;
    height: 100%;
    border-radius: 0 200px 200px 0;
    position: relative;
}

.exhibitor__graphism .graphism:nth-child(2) {
    width: 70%;
    height: 100%;
    border-radius: 200px 0 0 200px;
}

.exhibitor__graphism .graphism:nth-child(1):before {
    content: '';
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: -35px;
    width: 70px;
    height: 70px;
    background: url('/public/images/blue-star.svg#shape');
    background-repeat: no-repeat;
    background-size: contain;
    clip-path: url('/public/images/blue-star.svg#shape');
}

.exhibitor__graphism .graphism:nth-child(2):before {
    content: '';
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 30px;
  width: 70px;
  height: 70px;
  background: url('/public/images/blue-star.svg');
  background-repeat: no-repeat;
  background-size: contain;
  clip-path: url('/public/images/blue-star.svg');
}

.exhibitor__wrapper_row:nth-child(odd) .exhibitor__graphism .graphism {
    background-color: #FFFFFF;
}

.exhibitor__wrapper_row:nth-child(odd) .exhibitor__graphism .graphism:nth-child(2) {
    background-color: #FFFFFF;
}

.exhibitor__wrapper_row:nth-child(even) .exhibitor__graphism .graphism {
    background-color: #DADADA;
}

.exhibitor__wrapper_row:nth-child(even) .exhibitor__graphism .graphism:nth-child(2) {
    background-color: #DADADA;
}

.exhibitor__wrapper_row .exhibitor__row {
    width: 85vw;
    display: flex;
    justify-content: flex-start;
    gap: 2rem;
    height: 100%;
}

.exhibitor__wrapper_row:nth-child(odd) .exhibitor__row {
    background-color: #FFFFFF;
}
.exhibitor__wrapper_row:nth-child(even) .exhibitor__row.exhibitor__row {
    background-color: #DADADA;
}

@media only screen and (min-width: 3000px) {
    .exhibitor__container header h1 {
        font-size: 6rem;
    }

    .exhibitor__container header img {
        height: 6rem;
    }

    .exhibitor__row {
        gap: 4.5rem;
    }
}

@media screen and (min-width: 1920px) {
    .exhibitor__graphism .graphism:nth-child(2):before {
      right: 80px;
    }
}
.exhibitorcard__container {
    width: 20rem;
    padding: 1rem 0;
    display: flex;
    align-items: center;
    gap: 0.8rem;
}

.exhibitorcard__container .exhibitorcard__user_info {
    display: flex;
    flex-direction: column;
}

.exhibitorcard__container .exhibitorcard__user_info h1 {
    font-family: 'Montserrat';
    font-size: 1rem;
    font-weight: 700;
    line-height: 1.5rem;
    letter-spacing: 0em;
    text-align: left;
    color: #265AA0;
    max-width: 8rem;
    max-height: 3rem;
    overflow: hidden;
    text-overflow: ellipsis;
}

.exhibitorcard__container .exhibitorcard__user_info p {
    font-family: 'Poppins';
    font-size: 0.75rem;
    font-weight: 400;
    line-height: 1rem;
    letter-spacing: 0em;
    text-align: left;
    color: #265AA0;
    max-width: 8rem;
}

@media screen and (min-width: 3000px) {
    .exhibitorcard__container {
        max-width: 39rem;
        min-width: 34rem;
        padding: 3rem 0;
        gap: 1.5rem;
    }

    .exhibitorcard__container .exhibitorcard__user_info h1 {
        font-size: 2rem;
        max-width: 16.875rem;
        max-height: 9rem;
        line-height: 3rem;
    }

    .exhibitorcard__container .exhibitorcard__user_info p {
        font-size: 1.5rem;
        line-height: 3rem;
    }
}
.camera__container {
    display: flex;
    flex-direction: column;
}

.camera__container label {
    font-family: 'Poppins';
    font-size: 12px;
    font-weight: 700;
    line-height: 16px;
    letter-spacing: 0em;
    text-align: left;
    color: #3C3C3B;
}

.camera__container label span {
    color: #E52330;
}

.camera__container .camera__upload_container {
    display: flex;
    align-items: center;
    gap: 16px;
}

.camera__container .camera__upload_container .closeButton {
    width: 20px;
    height: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 2px solid #575756;
    border-radius: 50%;
    margin-top: 8px;
    cursor: pointer;
}

.camera__container .camera__upload_wrapper {
    width: 50%;
    padding: 16px;
    margin-top: 8px;
    border-radius: 4px;
    border: 1px solid #265AA0;
    color: #265AA0;
    background-color: #FFFFFF;
    position: relative;
    display: flex;
    gap: 8px;
}

.camera__container .camera__upload_wrapper.ready {
    border: 1px solid #078348;
    color: #078348;
    overflow: hidden;
}

.camera__container .camera__upload_wrapper.ready p {
    color: #078348;
    width: 3rem;
    white-space: nowrap;
    text-overflow: ellipsis;
}


.camera__container .camera__upload_wrapper p {
    font-family: 'Poppins';
    font-size: 12px;
    font-weight: 400;
    line-height: 16px;
    letter-spacing: 0em;
    text-align: left;
    color: #265AA0;
}

.camera__container .camera__upload_wrapper input {
    width: 100%;
    height: 100%;
    opacity: 0;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 10;
}

.hidden {
    display: none;
}
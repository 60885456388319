.inputtext__container input {
    width: 100%;
    padding: 16px;
    margin-top: 8px;
    border-radius: 4px;
    border: 2px solid #B2B2B2;
    background-color: #FFFFFF;
}

.inputtext__container label {
    font-family: 'Poppins';
    font-size: 12px;
    font-weight: 700;
    line-height: 16px;
    letter-spacing: 0em;
    text-align: left;
    color: #3C3C3B;
}

.inputtext__container label span {
    color: #E52330;
}

.inputtext__container .input__content {
    position: relative;
}

.inputtext__container .input__content .closeButton {
    position: absolute;
    top: 24px;
    right: 16px;
    z-index: 10;
    width: 20px;
    height: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 2px solid #575756;
    border-radius: 50%;
    cursor: pointer;
}
.checkin__container {
    min-height: 100vh;
    padding: 16px 32px;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 16px;
    background-color: #EDEDED;
    position: relative;
}

.checkin__container h1 {
    font-family: 'Montserrat';
    font-size: 16px;
    font-weight: 700;
    line-height: 24px;
    letter-spacing: 0em;
    text-align: center;
    color: #265AA0;
}

.checkin__container .checkin__content {
    width: 100%;
    height: 542.01px;
    border-radius: 4px;
    background-color: #FFFFFF;
    overflow: hidden;
    position: relative;
}

.checkin__container .checkin__content header {
    background: linear-gradient(135.44deg, #3F70B7 -48.81%, #2A5CA5 20.34%, #1A2C4C 83.96%);
    width: 100%;
    height: 126.58px;
    display: flex;
    flex-direction: column;
    gap: 8px;
    align-items: center;
    justify-content: center;
    position: relative;
    font-family: 'Poppins';
}

.header__year {
    display: flex;
    flex-direction: row;
    gap: 2px;
    align-items: center;
    justify-content: center;
}

.checkin__container .checkin__content header > p {
    font-family: 'Poppins';
    font-size: 10px;
    font-weight: 400;
    line-height: 16px;
    letter-spacing: 0.3em;
    text-align: center;
}

.header__year span {
    font-size: 12px;
    font-weight: 400;
    line-height: 16px;
    letter-spacing: 0.3em;
    color: #FFFFFF;
    position: relative;
    left: 2px;
    
}

.checkin__stars {
    height: 20px;
}

.checkin__stars.left {
    transform: scaleX(-1);
}

.checkin__logo {
    height: 26.58px;
}

.checkin__footer_image_side {
    height: 118.58px;
    position: absolute;
    top: 0;
}

.checkin__footer_image_side.left {
    left: 0;
    transform: scaleX(-1);
}

.checkin__footer_image_side.right {
    right: 0;
}

.checkin__footer_image_center {
    width: 240px;
}

.profileinfo__container {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 8px;
    position: relative;
    top: -12px
}

.profileinfo__container p {
    font-family: 'Poppins';
    font-size: 10px;
    font-weight: 500;
    line-height: 14px;
    letter-spacing: 0em;
    text-align: center;
    color: #265AA0;
}

.profileinfo__container .profileinfo__name {
    max-width: 229px;
    padding: 2px 12px;
    background-color: #F39315;
    border-radius: 50px;
}

.profileinfo__container .profileinfo__name p{
    font-family: 'Montserrat';
    font-size: 10px;
    font-weight: 700;
    line-height: 14px;
    letter-spacing: 0em;
    text-align: left;
    color: #FFFFFF;
    text-align: center;
}

.profileinfo__image {
    width: 175px;
    height: 175px;
    position: relative;
}

.profileinfo__image .profileinfo__image_wrapper {
    width: 100%;
    height: 100%;
    overflow: hidden;
    border: 3px solid #F39315;
    border-radius: 50%;
    overflow: hidden;
    position: relative;
}

.profileinfo__image .profileinfo__image_wrapper .checkin__profile_image{
    width: 100%;
    object-fit: contain;
}

.profileinfo__image .checkin__profile_stars {
    height: 38px;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
}

.profileinfo__image .checkin__profile_stars.right {
    right: -17px;
}

.profileinfo__image .checkin__profile_stars.left {
    left:  -17px;
}

.checkin__start_group {
    display: flex;
    flex-direction: column;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    gap: 10px;
}

.checkin__start_group.left {
    left: -34px;
}

.checkin__start_group.right {
    right: -34px;
}

.profileinfo__image .checkin__profile_stars_small {
    height: 22px;
}

.arrivalndeparture__container{
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: center;
    gap: 8px;
}

.arrivalndeparture__container h1 {
    font-family: 'Montserrat';
    font-size: 22px;
    font-weight: 900;
    line-height: 28px;
    letter-spacing: 0em;
    text-align: center;
    color: #1A2B4C;
}

.arrivalndeparture__container p {
    font-family: 'Poppins';
    font-size: 10px;
    font-weight: 500;
    line-height: 14px;
    letter-spacing: 0em;
    text-align: center;
    color: #1A2B4C;
}

.arrivalndeparture__container .arrival__container, .departure__container {
    display: flex;
    flex-direction: column;
    gap: 4px;
}

.arrivalndeparture__container .middle__container {
    display: flex;
    width: 96px;
    flex-direction: row;
    align-items: center;
    gap: 8px;
}

.arrivalndeparture__container .middle__container img {
    width: 24px;
}

.middleline__container {
    padding: 0 20px;
    margin: 12px 0;
}

.middleline__container .button__content{
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    gap: 11px;
}

.middleline__container .shareicon {
    font-size: 20px;
}

.travelinfo__container {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 51px;
}

.travelinfo__container .infos {
    display: flex;
    flex-direction: column;
}

.travelinfo__container .infos h2 {
    font-family: 'Poppins';
    font-size: 6px;
    font-weight: 700;
    line-height: 14px;
    letter-spacing: 0.3em;
    text-align: left;
    color: #F39315;
}

.travelinfo__container .infos p {
    font-family: 'Poppins';
    font-size: 14px;
    font-weight: 700;
    line-height: 20px;
    letter-spacing: 0em;
    text-align: left;
    color: #1A2B4C
}

.blue {
    background-color: #1A2B4C;
}

.yellow {
    background-color: #F39315;
}

.grey {
    background-color: #C6C6C6;
}

.line {
    width: auto;
    height: 1px;
}

.w28 {
    width: 28px;
}

.exhibitorimage__container {
    width: 6.5rem;
    height: 6.5rem;
    position: relative;
}

.exhibitorimage__container .exhibitorimage__wrapper {
    width: 100%;
    height: 100%;
    border-radius: 50%;
    border: 0.188rem solid #265AA0;
    overflow: hidden;
}

.exhibitorimage__container .exhibitorimage__user_image {
    width: 100%;
    object-fit: cover;
}

.exhibitorimage__container .exhibitorimage__star_image {
    width: 0.875rem;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
}

.exhibitorimage__container .exhibitorimage__star_image.left {
    left: -0.344rem;
}

.exhibitorimage__container .exhibitorimage__star_image.right {
    right: -0.344rem;
}

.flip-card {
  background-color: transparent;
  width: 6.5rem;
  height: 6.5rem;
  perspective: 1000px; /* Remove this if you don't want the 3D effect */
}
  
  /* This container is needed to position the front and back side */
.flip-card-inner {
  position: relative;
  width: 100%;
  height: 100%;
  text-align: center;
  transition: transform 0.8s;
  transform-style: preserve-3d;
}
  
  /* Do an horizontal flip when you move the mouse over the flip box container */
.flip-card.flip .flip-card-inner {
  transform: rotateX(360deg);
}
  
  /* Position the front and back side */
.flip-card-front, .flip-card-back {
  position: absolute;
  width: 100%;
  height: 100%;
  -webkit-backface-visibility: hidden; /* Safari */
  backface-visibility: hidden;
}
  
  /* Style the back side */
.flip-card-back {
  transform: rotateX(180deg);
}

@media screen and (min-width: 3000px) {
    .exhibitorimage__container {
        width: 12.564rem;
        height: 12.564rem;
    }

    .exhibitorimage__container .exhibitorimage__wrapper {
        border-width: 0.564rem;
    }

    .exhibitorimage__container .exhibitorimage__star_image {
        width: 2.625rem;
    }

    .exhibitorimage__container .exhibitorimage__star_image.left {
        left: -1.032rem;
    }
    
    .exhibitorimage__container .exhibitorimage__star_image.right {
        right: -1.032rem;
    }

    .flip-card {
      width: 12.564rem;
      height: 12.564rem;
    }
}

@media screen and (min-width: 1920px) {
  .exhibitorimage__container {
    width: 7.188rem;
    height: 7.188rem;
}

  .flip-card {
    background-color: transparent;
    width: 7.188rem;
    height: 7.188rem;
  }
}
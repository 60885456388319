.button {
    width: 100%;
    padding: 16px 0 16px 0;
    background-color:#F39315;
    border-radius: 50px;
    border: 0;
    color: #FFFFFF;
    font-family: 'Poppins';
    font-size: 14px;
    font-weight: 700;
    line-height: 20px;
    letter-spacing: 0em;
    text-align: center;

}

.button.disabled {
    background-color: #cecece;
}
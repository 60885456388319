.login__container {
    display: flex;
    flex-direction: column;
    background: linear-gradient(180deg, #3F70B7 0%, #2A5CA5 11.81%, #1A2C4C 22.67%);
    font-family: 'Poppins', sans-serif;
}

.login__logo {
    height: 29px;
}

.login__header {
    padding: 24px;
    width: 100%;
}

.login__header h1,p {
    color: #FFFFFF;
}

.login__header h1 {
    font-family: 'Montserrat', sans-serif;
    font-size: 24px;
    font-weight: 700;
    line-height: 32px;
    letter-spacing: 0em;
    text-align: left;
    margin: 16px 0 8px 0;
}

.login__header p {
    font-size: 12px;
    font-weight: 400;
    line-height: 16px;
    letter-spacing: 0em;
    text-align: left;
}


.login__footer {
    height: 159px;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.login__footer_image_center {
    width: 240px;
    margin: 24px 0 24px 0;
}
.login__footer_image_side {
    height: 184px;
    position: absolute;
    bottom: 0;
}

.login__footer_image_side.left {
    transform: scaleX(-1);
    left: 0;
}
.login__footer_image_side.right {
    right: 0;
}

.login__form {
    background-color: #FFFFFF;
    display: flex;
    flex-direction: column;
    gap: 24px;
    padding: 24px;
}

.login__form form {
    display: flex;
    flex-direction: column;
    gap: 24px;
}

.login__form p {
    font-size: 12px;
    font-weight: 400;
    line-height: 16px;
    letter-spacing: 0em;
    text-align: left;
    color: #3C3C3B;
}

.login__form p span {
    color: #E52330;
}
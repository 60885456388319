.select__container label {
    font-family: 'Poppins';
    font-size: 12px;
    font-weight: 700;
    line-height: 16px;
    letter-spacing: 0em;
    text-align: left;
    color: #3C3C3B;
}

.select__container label span {
    color: #E52330;
}

.select__container select {
    appearance: none;
    width: 100%;
    padding: 16px;
    border-radius: 4px;
    margin-top: 8px;
    border: 2px solid #B2B2B2;
    font-family: 'Poppins';
    font-size: 12px;
    font-weight: 400;
    line-height: 16px;
    letter-spacing: 0em;
    text-align: left;
    color: #706F6F;
}

.select__container select option {
    font-family: 'Poppins';
    font-size: 12px;
    font-weight: 400;
    line-height: 16px;
    letter-spacing: 0em;
    text-align: left;
}